'use strict'

export class Max {
  constructor (max) {
    this.max = max
  }
}

export class LimitedPerPoints {
  constructor (count, limit) {
    this.count = count
    this.limit = limit
  }
}

export class Unique {

}
