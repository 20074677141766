export default {
  translation: {
    'app-name': 'Departmento Munitorum',
    'select-game-system': 'Select game system',
    'select-army': 'Select army',

    'imperious-dominatus': 'Imperious Dominatus',
    'imperious-dominatus-description': 'Warfare in the Age of Horus',

    'netea-epicau-horus-heresy': 'EpicAU Horus Heresy',
    'netea-epicau-horus-heresy-description': 'Horus Heresy supplement for Net Epic Armageddon developed by the EpicAU community',
    'is-unofficial': 'This website is unofficial and is in no way endorsed by Games Workshop Limited',

    'unit-quantity': '{{quantity}}x {{type}}',
    'unit-upgrade': 'Replace 1x {{defaultType}} with 1x {{upgradeType}}',
    'unit-notes': '{{name}}: {{ notes }}',
    cost: '{{cost}} pts',
    free: 'Free',
    'update-name': 'Update name',
    'enter-new-name': 'Enter a new name for your list',
    'enter-new-detachment-name': 'Enter a new name for your detachment',
    'list-link': 'Share this list',
    'this-is-the-link': 'A link to this list has been copied to your clipboard',
    'delete-list': 'Delete list',
    'view-list': 'View List',
    'share-list': 'Share List',
    'invalid-message': '{{name}} was invalid',
    'remove-list': 'Remove this list?',
    'really-remove-list': 'Really remove this list?',
    'remove-detachment': 'Remove this detachment?',
    'really-remove-detachment': 'Really remove this detachment?',
    remove: 'Remove',
    list: 'List',
    'strategy-rating': 'Strategy Rating {{ strategyRating }}',
    'intitative-rating': 'Initiative Rating {{ initiativeRating }}+',
    'move-up': 'Move up',
    'move-down': 'Move down',
    'remaining-upgrades': '{{ remainingUpgrades }} remaining upgrades',
    'list-has-errors': 'This list has errors, please correct them before continuing',
    formations: 'Formations',

    'cannot-take-primarch-and-lord-commander': 'You cannot take a Primarch and a Lord Commander in the same list',
    'spacecraft-required': 'Spacecraft are required when units that cannot self-planetfall are present',
    'too-many-lords-of-war-or-allies': 'Too many points have been spent on Lords of War and/or Allies',
    'too-many-support-detachments': 'You have too many support detachments',
    'all-detachment-units-must-select-provenance': 'All eligible units in a detachment must select the same provenance',
    'too-many-provenances': 'Max two provenance types are allowed per army',
    'detachment-with-rogue-psyker-must-have-traitor-provenance': 'Rogue Psykers can only be added to detachments with the Traitor provenance',
    'daemonic-allies-require-at-least-one-detachment-with-traitor-provenance': 'If you take daemonic allies, at least one detachment must have the Traitor provenance',
    'units-with-chaos-spawn-mutations-must-have-traitor-provenance': 'Units with Chaos Spawn Mutations must have the Traitor provenance',
    'too-many-discipline-masters': 'You may only include one Discipline Master/Rogue Psyker per 500 points',
    'force-commander-should-have-discipline-master': 'If your army includes Discipline Masters/Rogue Psykers, one must be in the same formation as the Force Commander',
    'too-many-support-seneschals': 'Your army may only include one Seneschal',
    'too-many-daemonic-followers': 'Followers of Chaos detachments may only contain 9 of the Patron God\'s lesser daemons',
    'not-enough-daemon-hordes': 'For each Followers of Chaos detachment taken, one line detachment (of the same god affiliation or undivided) may be selected.',
    'greater-daemons-require-patron-hordes': 'Greater Daemon Hordes require a line detachment from the same Patron God to be present',
    'too-many-daemonic-overlords': 'Only one Daemon Prince/Herald may be a Daemonic Overlord',
    'only-one-patron-god': 'You may only include units from the patron god {{ patron }}',
    'there-can-be-only-one': 'Only one {{ type }} is allowed',
    'limited-quantity-per-points': 'Only {{ count }} {{ code }} detachment is allowed per full {{ limit }} points',
    'no-allied-supreme-commanders': 'Supreme Commanders may only come from the primary army list',
    'only-inf-and-ch-units-can-teleport': 'Only detachments made up of infantry and characters can have the teleport ability',
    'too-many-extra-bloodletters-or-fleshhounds': 'You may only take 1-6 extra Bloodletters and/or Flesh Hounds',
    'too-many-extra-bloodcrushers-or-skull-cannon': 'You may only take 1-6 extra Bloodcrushers and/or Skull Cannon',
    'too-many-extra-plaguebearers-or-nurglings': 'You may only take 1-6 extra Plaguebearers and/or Nurglings',
    'too-many-extra-beasts-of-nurgle-or-plague-drones': 'You may only take 1-6 extra Beasts of Nurgle and/or Plague Drones',
    'too-many-extra-daemonettes-or-seekers-of-slaanesh': 'You may only take 1-6 extra Daemonettes and/or Seekers of Slaanesh',
    'too-many-extra-seeker-chariots-or-fiends-of-slaanesh': 'You may only take 1-6 extra Seeker Chariots and/or Fiends of Slaanesh',
    'too-many-extra-horrors-or-flamers-or-screamers-of-tzeench': 'You may only take 1-6 extra Horror, Flamer and/or Screamers of Tzeench',

    'alpha-legion': 'Alpha Legion',
    'blood-angels': 'Blood Angels',
    'daemonic-hordes': 'Daemons Of The Ruinstorm',
    khorne: 'Khorne',
    slaanesh: 'Slaanesh',
    tzeench: 'Tzeench',
    nurgle: 'Nurgle',
    'dark-angels': 'Dark Angels',
    'death-guard': 'Death Guard',
    'emperors-children': "Emperor's Children",
    'imperial-fists': 'Imperial Fists',
    'imperial-militia': 'Imperial Militia',
    'iron-hands': 'Iron Hands',
    'iron-warriors': 'Iron Warriors',
    'knight-household': 'Knight Household',
    'legio-custodes': 'Legio Custodes',
    'legio-titanicus': 'Legio Titanicus',
    'mechanicum-taghmata': 'Mechanicum Taghmata',
    'night-lords': 'Night Lords',
    'raven-guard': 'Raven Guard',
    salamanders: 'Salamanders',
    'solar-auxilia': 'Solar Auxilia',
    'sons-of-horus': 'Sons of Horus',
    'space-wolves': 'Space Wolves',
    'thousand-sons': 'Thousand Sons',
    ultramarines: 'Ultramarines',
    'white-scars': 'White Scars',
    'word-bearers': 'Word Bearers',
    'world-eaters': 'World Eaters',
    'invalid-list': 'Invalid list',
    'invalid-list-message': "This list is invalid. Sorry it didn't work out.",

    'line-detachments': 'Line Detachments',
    'support-detachments': 'Support Detachments',
    'lords-of-war': 'Lords of War',
    allies: 'Allies',

    'followers-of-chaos': 'Followers of Chaos',
    'greater-daemon-hordes': 'Greater Daemon Hordes',

    'legion-artillery-battery': 'Artillery Battery',
    'legion-assault-detachment': 'Assault Detachment',
    'legion-assault-squad': 'Assault Squad',
    'legion-assault-support-squad': 'Assault Support Squad',
    'legion-assault-support-detachment': 'Assault Support Squad',
    'legion-attack-bike': 'Attack Bike',
    'legion-basilisk': 'Basilisk',
    'legion-battle-barge': 'Battle Barge',
    'legion-bike': 'Bike',
    'legion-breacher-detachment': 'Breacher Detachment',
    'legion-breacher-squad': 'Breacher Squad',
    'legion-breacher-support-squad': 'Breacher Squad',
    'legion-breacher-support-detachment': 'Breacher Support Squad',
    'legion-caestus': 'Caestus Assault Ram',
    'legion-cerberus-battery': 'Cerberus Super Heavy Tank Battery',
    'legion-cerberus': 'Cerberus Tank Destroyer',
    'legion-champion': 'Champion',
    'legion-chaplain': 'Chaplain',
    'legion-contemptor-dreadnought': 'Contemptor Dreadnought',
    'legion-contemptor-dreadnought-talon': 'Contemptor Dreadnought Talon',
    'legion-damoclese-command-rhino': 'Damoclese Command Rhino',
    'legion-deredeo-dreadnought': 'Deredeo Dreadnought',
    'legion-despoiler-detachment': 'Despoiler Detachment',
    'legion-despoiler-squad': 'Despoiler Squad',
    'legion-destroyer-detachment': 'Destroyer Detachment',
    'legion-destroyer-squad': 'Destroyer Squad',
    'legion-dreadnought': 'Dreadnought',
    'legion-dreadnought-talon': 'Dreadnought Talon',
    'legion-drop-pod': 'Drop Pod',
    'legion-falchion': 'Falchion Super Heavy Tank Destroyer',
    'legion-falchion-detachment': 'Falchion Detachment',
    'legion-fellblade': 'Fellblade Super Heavy Tank',
    'legion-fellblade-detachment': 'Fellblade Detachment',
    'legion-gunship': 'Fire Raptor Gunship',
    'legion-glaive': 'Glaive Super Heavy Tank',
    'legion-glaive-detachment': 'Glaive Detachment',
    'legion-gunship-wing': 'Gunship Wing',
    'legion-heavy-support-squad': 'Heavy Support Squad',
    'legion-interceptor-attack-wing': 'Interceptor Attack Wing',
    'legion-javelin-attack-speeder': 'Javelin Attack Speeder',
    'legion-javelin-attack-speeder-squadron': 'Javelin Attack Speeder Squadron',
    'legion-kharybdis': 'Kharybdis Assault Claw',
    'legion-land-raider-achilles': 'Land Raider Achilles',
    'legion-land-raider-achilles-transport': 'Land Raider Achilles Transport',
    'legion-land-raider-phobos': 'Land Raider Phobos',
    'legion-land-raider-phobos-transport': 'Land Raider Phobos Transport',
    'legion-land-raider-phobos-squadron': 'Land Raider Phobos Squadron',
    'legion-land-raider-proteus': 'Land Raider Proteus',
    'legion-land-raider-proteus-transport': 'Land Raider Proteus Transport',
    'legion-land-raider-proteus-squadron': 'Land Raider Proteus Squadron',
    'legion-land-raider-squadron': 'Land Raider Squadron',
    'legion-land-speeder': 'Land Speeder',
    'legion-land-speeder-squadron': 'Land Speeder Squadron',
    'legion-leviathan-dreadnought': 'Leviathan Dreadnought',
    'legion-leviathan-support-dreadnought': 'Leviathan Dreadnought',
    'legion-leviathan-dreadnought-talon': 'Leviathan Dreadnought Talon',
    'legion-librarian': 'Librarian',
    'legion-lieutenant-commander': 'Lieutenant Commander',
    'legion-lord-commander': 'Lord Commander',
    'legion-mastodon': 'Mastodon Heavy Assault Transport',
    'legion-medusa': 'Medusa',
    'legion-outrider-squadron': 'Outrider Squadron',
    'legion-predator-executioner': 'Predator Executioner',
    'legion-predator-infernus': 'Predator Infernus',
    'legion-predator': 'Predator',
    'legion-predator-strike-squadron': 'Predator Strike Squadron',
    'legion-rapier': 'Rapier',
    'legion-rapier-support': 'Rapier',
    'legion-rapier-weapons-battery': 'Rapier Weapons Battery',
    'legion-reconnaissance-detachment': 'Reconnaissance Detachment',
    'legion-reconnaissance-squad': 'Reconnaissance Squad',
    'legion-rhino': 'Rhino Armoured Carrier',
    'legion-sabre-strike-squadron': 'Sabre Strike Squadron',
    'legion-scimitar-jetbike': 'Scimitar Jetbike',
    'legion-sicaran': 'Sicaran Battle Tank',
    'legion-sicaran-venator': 'Sicaran Venator',
    'legion-sicaran-omega': 'Sicaran Omega Tank Destroyer',
    'legion-sicaran-battle-tank-squadron': 'Sicaran Battle Tank Squadron',
    'legion-sicaran-battle-tank-squadron-unit': 'Sicaran Battle Tank',
    'legion-spartan': 'Spartan Assault Tank',
    'legion-sky-hunter-attack-squadron': 'Sky Hunter Attack Squadron',
    'legion-spacecraft': 'Spacecraft',
    'legion-storm-eagle-attack-wing': 'Storm Eagle Attack Wing',
    'legion-storm-eagle-attack-ship': 'Storm Eagle Attack Ship',
    'legion-stormbird': 'Storm Bird Heavy Attack Ship',
    'legion-stormbird-wing': 'Stormbird Wing',
    'legion-strike-cruiser': 'Strike Cruiser',
    'legion-super-heavy-tank': 'Super Heavy Tank',
    'legion-super-heavy-tank-destroyer': 'Super Heavy Tank Destroyer',
    'legion-super-heavy-tank-battery': 'Super Heavy Tank Battery',
    'legion-tactical-detachment': 'Tactical Detachment',
    'legion-tactical-squad': 'Tactical Squad',
    'legion-tactical-support-squad': 'Tactical Support Squad',
    'legion-terminator-detachment': 'Terminator Detachment',
    'legion-terminator-squad': 'Terminator Squad',
    'legion-teleport': 'Teleport',
    'legion-thunderhawk-gunship': 'Thunderhawk Gunship',
    'legion-thunderhawk-gunship-wing': 'Thunderhawk Gunship Wing',
    'legion-thunderhawk-transporter': 'Thunderhawk Transporter',
    'legion-thunderhawk-transporter-wing': 'Thunderhawk Transporter Wing',
    'legion-typhon-battery': 'Typhon Super Heavy Tank Battery',
    'legion-typhon': 'Typhon Siege Tank',
    'legion-vindicator-squadron': 'Vindicator Squadron',
    'legion-vindicator-laser-destroyer': 'Vindicator Laser Destroyer',
    'legion-vindicator': 'Vindicator',
    'legion-vindicator-squadron-vindicator': 'Vindicator',
    'legion-whirlwind-hyperios': 'Whirlwind Hyperios',
    'legion-whirlwind-scorpius': 'Whirlwind Scorpius',
    'legion-whirlwind': 'Whirlwind',
    'legion-xiphon-interceptor': 'Xiphon Interceptor',
    'legion-terminator-company': 'Terminator Company',
    'legion-terminator-grand-company': 'Grand Terminator Company',
    'legion-command-section': 'Command Section',
    'legion-tactical-company': 'Tactical Company',
    'legion-tactical-grand-company': 'Grand Tactical Company',
    'legion-tactical-centurion-squad': 'Centurion Tactical Squad',
    'legion-tactical-decurion-squad': 'Decurion Tactical Squad',
    'legion-tactical-veteran-squad': 'Veteran Tactical Squad',
    'legion-despoiler-company': 'Despoiler Company',
    'legion-despoiler-grand-company': 'Grand Despoiler Company',
    'legion-despoiler-centurion-squad': 'Centurion Despoiler Squad',
    'legion-despoiler-decurion-squad': 'Decurion Despoiler Squad',
    'legion-despoiler-veteran-squad': 'Veteran Despoiler Squad',
    'legion-assault-company': 'Assault Company',
    'legion-assault-grand-company': 'Grand Assault Company',
    'legion-assault-centurion-squad': 'Centurion Assault Squad',
    'legion-assault-decurion-squad': 'Decurion Assault Squad',
    'legion-assault-veteran-squad': 'Veteran Assault Squad',
    'legion-breacher-company': 'Breacher Company',
    'legion-breacher-grand-company': 'Grand Breacher Company',
    'legion-breacher-centurion-squad': 'Centurion Breacher Squad',
    'legion-breacher-decurion-squad': 'Decurion Breacher Squad',
    'legion-breacher-veteran-squad': 'Veteran Breacher Squad',

    'sons-of-horus-justaerin-terminator-detachment': 'Justaerin Terminator Detachment',
    'sons-of-horus-justaerin-terminator-squad': 'Justaerin Terminator Squad',
    'sons-of-horus-primarch-detachment': 'Primarch',
    'sons-of-horus-primarch': 'Horus Lupercal, the Warmaster, Breaker of Tyrants',
    'sons-of-horus-reaver-attack-detachment': 'Reaver Attack Detachment',
    'sons-of-horus-reaver-attack-squad': 'Reaver Attack Squad',
    'sons-of-horus-bodyguard-squad': 'Terminator Squad',

    'night-lords-night-raptor-detachment': 'Night Raptor Detachment',
    'night-lords-night-raptor-squad': 'Night Raptor Squad',
    'night-lords-terror-detachment': 'Terror Detachment',
    'night-lords-terror-squad': 'Terror Squad',
    'night-lords-destroyer-detachment': 'Destroyer Detachment',
    'night-lords-primarch-detachment': 'Primarch',
    'night-lords-primarch': 'Konrad Curze, The Night Haunter',
    'night-lords-bodyguard-squad': 'Tactical Squad',

    'world-eaters-rampager-detachment': 'Rampager Detachment',
    'world-eaters-rampager-squad': 'Rampager Squad',
    'world-eaters-red-butcher-detachment': 'Red Butcher Detachment',
    'world-eaters-red-butcher-squad': 'Red Butcher Squad',
    'world-eaters-destroyer-detachment': 'Destroyer Detachment',
    'world-eaters-primarch-detachment': 'Primarch',
    'world-eaters-primarch': 'Angron, Master of the World Eaters',
    'world-eaters-bodyguard-squad': 'Terminator Squad',

    'emperors-children-palatine-blades-detachment': 'Palatine Blades Detachment',
    'emperors-children-palatine-blades-squad': 'Palatine Blades Squad',
    'emperors-children-kakophoni-detachment': 'Kakophoni Detachment',
    'emperors-children-kakophoni-squad': 'Kakophoni Squad',
    'emperors-children-phoenix-terminator-detachment': 'Phoenix Terminator Detachment',
    'emperors-children-phoenix-terminator-squad': 'Phoenix Terminator Squad',
    'emperors-children-primarch-detachment': 'Primarch',
    'emperors-children-primarch': 'Fulgrim, the Illuminator',
    'emperors-children-bodyguard-squad': 'Terminator Squad',

    'iron-warriors-iron-havoc-detachment': 'Iron Havoc Detachment',
    'iron-warriors-iron-havoc-squad': 'Iron Havoc Support Squad',
    'iron-warriors-terminator-detachment': 'Terminator Detachment',
    'iron-warriors-tyrant-siege-terminator-squad': 'Tyrant Siege Terminator Squad',
    'iron-warriors-terminator-squad-extra': 'Terminator Squad',
    'iron-warriors-terminator-squad': 'Terminator Squad',
    'iron-warriors-artillery-battery-detachment': 'Artillery Battery',
    'iron-warriors-medusa': 'Medusa',
    'iron-warriors-whirlwind': 'Whirlwind',
    'iron-warriors-basilisk': 'Basilisk',
    'iron-warriors-super-heavy-tank-squadron': 'Super Heavy Tank Squadron',
    'iron-warriors-stormblade': 'Stormblade',
    'iron-warriors-fellblade': 'Fellblade',
    'iron-warriors-tormentor': 'Tormentor',
    'iron-warriors-primarch-detachment': 'Primarch',
    'iron-warriors-primarch': 'Perturabo, Primarch of the Iron Warriors',
    'iron-warriors-bodyguard-squad': 'Terminator Squad',
    'iron-warriors-iron-circle': 'Iron Circle',

    'death-guard-primarch': 'Mortarion, the Pale King',
    'death-guard-primarch-detachment': 'Primarch',
    'death-guard-deathshroud-terminator-squad': 'Deathshroud Terminator Squad',
    'death-guard-deathshroud-terminator-detachment': 'Deathshroud Terminator Detachment',
    'death-guard-grave-warden-terminator-squad': 'Grave Warden Terminator Squad',
    'death-guard-grave-warden-terminator-detachment': 'Grave Warden Terminator Detachment',
    'death-guard-destroyer-detachment': 'Destroyer Detachment',
    'death-guard-bodyguard-squad': 'Terminator Squad',

    'word-bearers-primarch': 'Lorgar, the Voice of Truth',
    'word-bearers-primarch-detachment': 'Primarch',
    'word-bearers-bodyguard-squad': 'Tactical Squad',
    'word-bearers-gal-vorbak-dark-brethren-detachment': 'Gal Vorbak Dark Brethren',
    'word-bearers-gal-vorbak-dark-brethren-squad': 'Gal Vorbak Dark Brethren',
    'word-bearers-ashen-circle-detachment': 'Ashen Circle Detachment',
    'word-bearers-incendiary-squad': 'Incendiary Squad',
    'word-bearers-destroyer-detachment': 'Destroyer Detachment',
    'word-bearers-mhara-gal-tainted-dreadnought-talon': 'Mhara Gal Tainted Dreadnought Talon',
    'word-bearers-mal-ghara-tainted-contemptor-dreadnought': 'Mhara Gal Tainted Dreadnought',

    'alpha-legion-head-hunter-kill-team-detachment': 'Head Hunter Kill Team',
    'alpha-legion-head-hunter-kill-team-squad': 'Head Hunter Kill Team',
    'alpha-legion-lernaean-terminator-detachment': 'Lernaean Terminator Detachment',
    'alpha-legion-lernaean-terminator-squad': 'Lernaean Terminator Squad',
    'alpha-legion-primarch-detachment': 'Primarch',
    'alpha-legion-primarch': 'Alpharius & Omegon',
    'alpha-legion-bodyguard-squad': 'Tactical Squad',

    'thousand-sons-sekhmet-terminator-detachment': 'Sekhmet Terminator Cabal',
    'thousand-sons-sekhmet-terminator-squad': 'Sekhmet Terminator Squad',
    'thousand-sons-ammitara-intercessor-detachment': 'Ammitara Occult Intercession Cabal',
    'thousand-sons-ammitara-intercessor-squad': 'Ammitara Intercessor Squad',
    'thousand-sons-khenetai-blades-detachment': 'Khenetai Occult Blade Cabal',
    'thousand-sons-khenetai-blades-squad': 'Khenetai Blades',
    'thousand-sons-primarch-detachment': 'Primarch',
    'thousand-sons-primarch': 'Magnus the Red, the Crimson King',
    'thousand-sons-bodyguard-squad': 'Sekhmet Terminator Squad',

    'iron-hands-predator-detachment': 'Predator Detachment',
    'iron-hands-predator': 'Predator',
    'iron-hands-predator-infernus': 'Predator Infernus',
    'iron-hands-predator-executioner': 'Predator Executioner',
    'iron-hands-whirlwind-scorpius': 'Whirlwind Scorpius',
    'iron-hands-gorgon-terminator-detachment': 'Gorgon Terminator Detachment',
    'iron-hands-gorgon-terminator-squad': 'Gorgon Terminator Squad',
    'iron-hands-medusan-immortals-detachment': 'Medusan Immortals Detachment',
    'iron-hands-medusan-immortals-squad': 'Medusan Immortals Squad',
    'iron-hands-destroyer-detachment': 'Destroyer Detachment',
    'iron-hands-primarch-detachment': 'Primarch',
    'iron-hands-primarch': 'Ferrus Manus, the Bane of Asirnoth',
    'iron-hands-bodyguard-squad': 'Gorgon Terminator Squad',
    'iron-hands-super-heavy-tank-squadron': 'Super Heavy Tank Squadron',
    'iron-hands-fellblade': 'Fellblade',
    'iron-hands-glaive': 'Glaive',

    'raven-guard-dark-fury-assault-detachment': 'Dark Fury Assault Detachment',
    'raven-guard-dark-fury-assault-squad': 'Dark Fury Assault Squad',
    'raven-guard-mor-deythan-strike-detachment': 'Mor Deythan Strike Detachment',
    'raven-guard-mor-deythan-strike-squad': 'Mor Deythan Strike Squad',
    'raven-guard-darkwing-pattern-storm-eagle-wing': 'Darkwing Pattern Storm Eagle Wing',
    'raven-guard-darkwing-pattern-storm-eagle': 'Darkwing Pattern Storm Eagle',
    'raven-guard-destroyer-detachment': 'Destroyer Detachment',
    'raven-guard-primarch-detachment': 'Primarch',
    'raven-guard-primarch': 'Corvus Corax, the Shadow Lord',
    'raven-guard-bodyguard-squad': 'Assault Squad',

    'salamanders-firedrake-terminator-detachment': 'Firedrake Terminator Detachment',
    'salamanders-firedrake-terminator-squad': 'Firedrake Terminator Squad',
    'salamanders-pyroclast-detachment': 'Pyroclast Detachment',
    'salamanders-pyroclast-squad': 'Pyroclast Squad',
    'salamanders-primarch-detachment': 'Primarch',
    'salamanders-primarch': 'Vulkan, the Promethean Fire',
    'salamanders-bodyguard-squad': 'Firedrake Terminator Squad',

    'ultramarines-fulmentarus-terminator-detachment': 'Fulmentarus Strike Detachment',
    'ultramarines-fulmentarus-terminator-squad': 'Fulmentarus Terminator Squad',
    'ultramarines-loctarus-detachment': 'Loctarus Storm Detachment',
    'ultramarines-loctarus-storm-squad': 'Loctarus Storm Squad',
    'ultramarines-destroyer-detachment': 'Destroyer Detachment',
    'ultramarines-invictarus-suzerain-detachment': 'Invictarus Suzerain Detachment',
    'ultramarines-invictarus-suzerain-squad': 'Invictarus Suzerain Squad',
    'ultramarines-primarch-detachment': 'Primarch',
    'ultramarines-primarch': 'Roboute Guilliman, Master of Ultramar',
    'ultramarines-bodyguard-squad': 'Tactical Squad',
    'ultramarines-damocles-rhino': 'Damocles Command Rhino',
    'ultramarines-tactical-detachment': 'Tactical Detachment',
    'ultramarines-rhino-transports': 'Rhinos',
    'ultramarines-rhino': 'Rhino Armoured Carrier',

    'imperial-fists-phalanx-warder-detachment': 'Phalanx Warder Detachment',
    'imperial-fists-phalanx-warder-squad': 'Phalanx Warder Squad',
    'imperial-fists-templar-brethren-detachment': 'Templar Brethren Detachment',
    'imperial-fists-templar-brethren-squad': 'Templar Brethren Squad',
    'imperial-fists-destroyer-detachment': 'Destroyer Detachment',
    'imperial-fists-castellum-stronghold-detachment': 'Castellum Stronghold',
    'imperial-fists-castellum-stronghold': 'Castellum Stronghold',
    'imperial-fists-castellum-stronghold-critical-hit': 'The stronghold loses an additional DC. When the stronghold\'s DC is reduced to 0, it is destroyed, any units in base contact with the stronghold suffer a standard hit on a 5+.',
    'imperial-fists-primus-redoubt-detachment': 'Primus Redoubt',
    'imperial-fists-primus-redoubt': 'Primus Redoubt',
    'imperial-fists-primus-redoubt-critical-hit': 'Roll a D6 in the end phase for the remainder of the game, on a 1, the Primus Redoubts reactor explodes, destroying it completely and hitting all units within 5cm on a standard 4+ to hit, on a 2-3, the Redoubt loses a point of DC, 4-6 the reactor is repaired. When the Redoubt\'s DC is reduced to 0, it is destroyed, any units in base contact with the stronghold suffer a standard hit on a 5+.',
    'imperial-fists-super-heavy-tank-squadron': 'Super Heavy Tank Squadron',
    'imperial-fists-fellblade': 'Fellblade',
    'imperial-fists-primarch-detachment': 'Primarch',
    'imperial-fists-primarch': 'Rogal Dorn, Praetorian of Terra',
    'imperial-fists-bodyguard-squad': 'Tactical Squad',

    'blood-angels-primarch-detachment': 'Primarch',
    'blood-angels-primarch': 'Sanguinius, the Angel',
    'blood-angels-bodyguard-squad': 'Assault Squad',
    'blood-angels-angels-tears-detachment': 'Angel\'s Tears Detachment',
    'blood-angels-angels-tears-squad': 'Angel\'s Tears Squad',
    'blood-angels-crimson-paladins-detachment': 'Crimson Paladins Detachment',
    'blood-angels-crimson-paladins-squad': 'Crimson Paladins Squad',
    'blood-angels-dawnbreaker-detachment': 'Dawnbreaker Cohort',
    'blood-angels-dawnbreaker-squad': 'Dawnbreaker Squad',

    'dark-angels-destroyer-detachment': 'Destroyer Detachment',
    'dark-angels-primarch-detachment': 'Primarch',
    'dark-angels-primarch': 'Lion El\'Johnson, the Son of the Forest',
    'dark-angels-bodyguard-squad': 'Terminator Squad',
    'dark-angels-super-heavy-tank-squadron': 'Super Heavy Tank Squadron',
    'dark-angels-fellblade': 'Fellblade',
    'dark-angels-glaive': 'Glaive',
    'dark-angels-tactical-detachment': 'Tactical Detachment',
    'dark-angels-heavy-support-squad': 'Heavy Support Squad',
    'dark-angels-tactical-support-squad': 'Tactical Support Squad',
    'dark-angels-dreadwing-interemptor-detachment': 'Dreadwing Interemptor Detachment',
    'dark-angels-dreadwing-interemptor-squad': 'Dreadwing Interemptor Squad',

    'white-scars-outrider-detachment': 'Outrider Detachment',
    'white-scars-attack-bike': 'Attack Bike',
    'white-scars-bike': 'Bike',
    'white-scars-sky-hunter-attack-squadron': 'Sky Hunter Attack Squadron',
    'white-scars-scimitar-jetbike': 'Scimitar Jetbike',
    'white-scars-primarch-detachment': 'Primarch',
    'white-scars-primarch': 'Jaghatai Khan, the Great Khan',
    'white-scars-bodyguard-squad': 'Terminator Squad',
    'white-scars-ebon-keshig': 'Ebon Keshig',
    'white-scars-ebon-keshig-detachment': 'Ebon Keshig',
    'white-scars-golden-keshig': 'Golden Keshig',
    'white-scars-chogorian-brotherhood': 'Chogorian Brotherhood',
    'white-scars-chogorian-brotherhood-bike': 'Bike',
    'white-scars-chogorian-brotherhood-attack-bike': 'Attack Bike',
    'white-scars-destroyer-detachment': 'Destroyer Detachment',
    'white-scars-javelin-attack-detachment': 'Javelin Attack Speeder Squadron',
    'white-scars-kyzagan-assault-speeder': 'Kyzagan Assault Speeder',

    'space-wolves-grey-slayer-detachment': 'Grey Slayer Warband',
    'space-wolves-grey-slayer-squad': 'Grey Slayer Pack',
    'space-wolves-deathsworn-detachment': 'Deathsworn Warband',
    'space-wolves-deathsworn-squad': 'Deathsworn Pack',
    'space-wolves-varagyr-wolf-guard-detachment': 'Wolf Guard Detachment',
    'space-wolves-varagyr-wolf-guard-squad': 'Vragyr Wolf Guard Squad',
    'space-wolves-primarch-detachment': 'Primarch',
    'space-wolves-primarch': 'Leman Russ, the Wolf King',
    'space-wolves-bodyguard-squad': 'Vragyr Wolf Guard Squad',

    'legio-titanicus-warhound-titan-detachment': 'Warhound Titan',
    'legio-titanicus-warhound-titan-pack-detachment': 'Warhound Scout Pack',

    'legio-titanicus-warhound-scount-titan': 'Warhound Titan',
    'legio-titanicus-warhound-scount-titan-pack-unit': 'Warhound Titan',
    'legio-titanicus-reaver-titan-detachment': 'Reaver Titan',
    'legio-titanicus-reaver-battle-titan': 'Reaver Titan',
    'legio-titanicus-warlord-titan-detachment': 'Warlord Titan',
    'legio-titanicus-warlord-battle-titan': 'Warlord Titan',
    'legio-titanicus-emperor-class-titan-detachment': 'Emperor Class Titan',
    'legio-titanicus-imperator-support-titan': 'Imperator Support Titan',
    'legio-titanicus-warmonger-support-titan': 'Warmonger Support Titan',
    'legio-titanicus-veteran-princeps': 'Veteran Princeps',
    'legio-titanicus-legate': 'Legate',
    'legio-titanicus-air-defence': 'Air Defence',
    'legio-titanicus-sacred-icon': 'Sacred Icon',

    'legio-custodes-hykanatoi-detachment': 'Hykanatoi Detachment',
    'legio-custodes-sentinel-guard': 'Sentinal Guard',
    'legio-custodes-custodian-guard': 'Custodian Guard',
    'legio-custodes-sagittarum-custodes': 'Sagittarum Custodes',
    'legio-custodes-coronus-grav-carrier': 'Coronus Grav Carrier',
    'legio-custodes-captain-general': 'Captain General',
    'legio-custodes-tribune': 'Tribune',
    'legio-custodes-agamatus-detachment': 'Agamatus Jetbike Squadron',
    'legio-custodes-agamatus': 'Agamatus Jetbike',
    'legio-custodes-aquilion-terminator-detachment': 'Aquilion Terminator Detachment',
    'legio-custodes-aquilion-terminator': 'Aquilion Terminator Squad',
    'legio-custodes-ephoroi-custodes-detachment': 'Ephoroi Custodes Detachment',
    'legio-custodes-ephoroi-custodes': 'Ephoroi Custodes Squad',
    'legio-custodes-venatari-detachment': 'Venatari Detachment',
    'legio-custodes-venatari-squad': 'Venatari Squad',
    'legio-custodes-sisters-of-silence-detachment': 'Sisters of Silence Detachment',
    'legio-custodes-sisters-of-silence': 'Sisters of Silence Squad',
    'legio-custodes-pallas-grav-attack-squadron': 'Pallas Grav Attack Vehicle Squadron',
    'legio-custodes-pallas-grav-attack-vehicle': 'Pallas Grav Attack Vehicle',
    'legio-custodes-caladius-grav-tank-squadron': 'Caladius Grav Tank Squadron',
    'legio-custodes-caladius-grav-tank': 'Caladius Grav Tank',
    'legio-custodes-morotoi-detachment': 'Morotoi Dreadnought Detachment',
    'legio-custodes-contemptor-achillus-dreadnought': 'Contemptor Achilles Dreadnought',
    'legio-custodes-contemptor-galatus-dreadnought': 'Contemptor Galatus Dreadnought',
    'legio-custodes-telemon-heavy-dreadnought': 'Telemon Heavy Dreadnought',
    'legio-custodes-orion-assault-dropship-squadron': 'Orion Assault Dropship Squadron',
    'legio-custodes-orion-assault-dropship': 'Orion Assault Dropship',
    'legio-custodes-ares-strike-squadron': 'Ares Strike Squadron',
    'legio-custodes-ares-gunship': 'Ares-Gunship',

    'imperial-militia-command-squad': 'Auxilia Command Squad',
    'imperial-militia-force-commander': 'Force Commander',
    'imperial-militia-auxiliaries': 'Auxiliaries',
    'imperial-militia-infantry-squad': 'Auxilia Infantry Squad',
    'imperial-militia-platoon-commander': 'Platoon Commander',
    'imperial-militia-levy-squad': 'Inducted Levy Squad',
    'imperial-militia-levy-auxiliaries': 'Levy Auxiliaries',
    'imperial-militia-motorcycle-squad': 'Auxilia Motorcycle Squad',
    'imperial-militia-motorcycle-commander': 'Auxilia Motorcycle Commander',
    'imperial-militia-motorcycle': 'Auxilia Bikers',
    'imperial-militia-grenedier-squad': 'Grenedier Squad',
    'imperial-militia-grenedier': 'Auxilia Grenediers',
    'imperial-militia-gorgon': 'Gorgon',
    'imperial-militia-arvus-lighter': 'Arvus Lighter Orbital Shuttle',
    'imperial-militia-rhino': 'Auxilia Rhino',
    'imperial-militia-land-raider-proteus': 'Land Raider Proteus',
    'imperial-militia-fire-support': 'Fire Support Auxiliaries',
    'imperial-militia-support-auxiliaries': 'Auxiliaries',
    'imperial-militia-recon-auxiliaries': 'Recon Auxiliaries',
    'imperial-militia-ogryn-brute-squad': 'Ogryn Brute Squad',
    'imperial-militia-orbital-support': 'Orbital Support',
    'imperial-militia-emperor-class-battleship': 'Emperor Class Battleship',
    'imperial-militia-dauntless-class-light-cruiser': 'Dauntless Class Light Cruiser',
    'imperial-militia-cavalry-squad': 'Cavalry Squad',
    'imperial-militia-cavalry-auxiliary': 'Cavalry Auxiliaries',
    'imperial-militia-heavy-ordnance-battery': 'Heavy Ordnance Battery',
    'imperial-militia-basilisk-artillery-carriage': 'Basilisk Artillery Carriage',
    'imperial-militia-medusa-artillery-carriage': 'Medusa Artillery Carriage',
    'imperial-militia-malcador-heavy-tank-squadron': 'Malcador Heavy Tank Squadron',
    'imperial-militia-malcador': 'Malcador',
    'imperial-militia-malcador-annihilator': 'Malcador Annihilator',
    'imperial-militia-rapier-battery': 'Rapier Battery',
    'imperial-militia-rapier': 'Rapier',
    'imperial-militia-sentinel-scout-squadron': 'Sentinel Scout Squadron',
    'imperial-militia-sentinel': 'Sentinel',
    'imperial-militia-super-heavy-tank': 'Super Heavy Tank',
    'imperial-militia-baneblade': 'Baneblade',
    'imperial-militia-stormhammer': 'Stormhammer',
    'imperial-militia-battle-tank-squadron': 'Battle Tank Squadron',
    'imperial-militia-leman-russ': 'Leman Russ',
    'imperial-militia-leman-russ-demolisher': 'Leman Russ Demolisher',
    'imperial-militia-leman-russ-exterminator': 'Leman Russ Exterminator',
    'imperial-militia-leman-russ-vanquisher': 'Leman Russ Vanquisher',
    'imperial-militia-super-heavy-tank-platoon': 'Super Heavy Tank Platoon',
    'imperial-militia-baneblade-platoon': 'Baneblade',
    'imperial-militia-stormhammer-platoon': 'Stormhammer',
    'imperial-militia-avenger-wing': 'Avenger Wing',
    'imperial-militia-avenger-strike-fighter': 'Avenger Strike Fighter',
    'imperial-militia-primaris-wing': 'Primaris-Lightning Wing',
    'imperial-militia-primaris-strike-fighter': 'Primaris-Lightning Fighter',
    'imperial-militia-discipline-master': 'Discipline Master',
    'imperial-militia-rogue-psyker': 'Rogue Psyker',

    'mechanicum-taghmata-adsecularis-covenent': 'Adsecularis Covenent',
    'mechanicum-taghmata-tech-priest': 'Tech Priest',
    'mechanicum-taghmata-tech-thrall': 'Tech Thrall',
    'mechanicum-taghmata-krios-upgrade': 'Krios Battle Tank',
    'mechanicum-taghmata-archmagos-prime': 'Archmagos Prime',
    'mechanicum-taghmata-thallax-cohort': 'Thallax Cohort',
    'mechanicum-taghmata-thallax': 'Thallax',
    'mechanicum-taghmata-magos-prime': 'Magos Prime',
    'mechanicum-taghmata-scyllax': 'Scyllax Guardian-Automata',
    'mechanicum-taghmata-triaros': 'Triaros Armoured Conveyor',
    'mechanicum-taghmata-tech-priest-upgrade': 'Tech Priest',
    'mechanicum-taghmata-ursarax-cohort': 'Usarax Cohort',
    'mechanicum-taghmata-ursarax': 'Ursarax',
    'mechanicum-taghmata-land-raider': 'Land Raider',
    'mechanicum-taghmata-vorax-maniple': 'Vorax Maniple',
    'mechanicum-taghmata-vorax': 'Vorax Battle-Automata',
    'mechanicum-taghmata-castellax-maniple': 'Castellax Maniple',
    'mechanicum-taghmata-castellax': 'Castellax Battle-Automata',
    'mechanicum-taghmata-thanatar-maniple': 'Thantar Maniple',
    'mechanicum-taghmata-thanatar': 'Thantar Siege-Automata',
    'mechanicum-taghmata-thanatar-upgrade': 'Thantar Siege-Automata',
    'mechanicum-taghmata-krios-squadron': 'Krios Squadron',
    'mechanicum-taghmata-krios': 'Krios Battle Tanks',
    'mechanicum-taghmata-myrmidon-sect': 'Myrmidon Sect',
    'mechanicum-taghmata-myrmidon-secutors': 'Myrmidon Secutors',
    'mechanicum-taghmata-myrmidon-destructors': 'Myrmidon Destructors',
    'mechanicum-taghmata-tarantula-battery': 'Tarantula Battery',
    'mechanicum-taghmata-tarantula': 'Tarantula Sentry Guns',
    'mechanicum-taghmata-hyperios': 'Hyperios Platform',
    'mechanicum-taghmata-minotaur-battery': 'Minotaur Battery',
    'mechanicum-taghmata-minotaur': 'Minotaur Artillery Tanks',
    'mechanicum-taghmata-ordinatus-minoris-tormenta': 'Ordinatus Minoris Tormenta',
    'mechanicum-taghmata-ordinatus-minoris': 'Ordinatus Minoris',
    'mechanicum-taghmata-karacnos-squadron': 'Karacnos Squadron',
    'mechanicum-taghmata-karacnos': 'Karacnos Assault Tanks',
    'mechanicum-taghmata-avenger-wing': 'Imperial Navy Avenger Wing',
    'mechanicum-taghmata-avenger-strike-fighter': 'Avenger Strike Fighter',
    'mechanicum-taghmata-primaris-wing': 'Imperial Navy Primaris-Lightning Wing',
    'mechanicum-taghmata-primaris-strike-fighter': 'Primaris-Lightning Fighter',
    'mechanicum-taghmata-super-heavy-tank-destroyer': 'Super Heavy Tank Destroyer',
    'mechanicum-taghmata-falchion': 'Mechanicum Falchion',
    'mechanicum-taghmata-ordinatus-majoris-detachment': 'Ordinatus Majoris',
    'mechanicum-taghmata-ordinatus-majoris': 'Ordinatus Majoris',
    'mechanicum-taghmata-vultarax-stratos-automata-detachment': 'Vultarax Stratos Automata Detachment',
    'mechanicum-taghmata-vultarax-stratos-automata': 'Vultarax Stratos Automata',

    'knight-household-questoris-paladin-knights': 'Questoris Paladin Knights',
    'knight-household-questoris-paladin-knight': 'Knight Paladin',
    'knight-household-questoris-paladin-knight-extra': 'Knight Paladin',
    'knight-household-questoris-errant-knights': 'Questoris Errant Knights',
    'knight-household-questoris-errant-knight': 'Knight Errant',
    'knight-household-questoris-errant-knight-extra': 'Knight Errant',
    'knight-household-questoris-knight': 'Questoris Knight',
    'knight-household-questoris-knight-paladin': 'Knight Paladin',
    'knight-household-questoris-knight-errant': 'Knight Errant',
    'knight-household-questoris-knight-crusader': 'Knight Crusader',
    'knight-household-questoris-knight-gallant': 'Knight Gallant',
    'knight-household-questoris-knight-magera': 'Knight Magaera',
    'knight-household-questoris-knight-styrix': 'Knight Styrix',
    'knight-household-questoris-knight-warden': 'Knight Warden',
    'knight-household-questoris-knight-crusader-extra': 'Knight Crusader',
    'knight-household-questoris-knight-gallant-extra': 'Knight Gallant',
    'knight-household-questoris-knight-magera-extra': 'Knight Magaera',
    'knight-household-questoris-knight-styrix-extra': 'Knight Styrix',
    'knight-household-questoris-knight-warden-extra': 'Knight Warden',
    'knight-household-cerastus-lancer-knights': 'Cerastus Knight Lancers',
    'knight-household-cerastus-lancer-knight': 'Knight Lancer',
    'knight-household-cerastus-castigator-knights': 'Cerastus Knight Castigators',
    'knight-household-cerastus-castigator-knight': 'Cerastus Knight Castigator',
    'knight-household-cerastus-knight': 'Cerastus Knight',
    'knight-household-cerastus-knight-lancer': 'Knight Lancer',
    'knight-household-cerastus-knight-castigator': 'Knight Castigator',
    'knight-household-cerastus-knight-atropos': 'Knight Atropos',
    'knight-household-cerastus-knight-acheron': 'Knight Acheron',
    'knight-household-acastus-knight-porphyrions': 'Porphyrion Knights',
    'knight-household-acastus-knight-porphyrion': 'Knight Porphyrion',
    'knight-household-armiger-knights': 'Armiger Knights',
    'knight-household-armiger-knight-warglaive': 'Armiger Knight-Warglaives',
    'knight-household-armiger-knight-helverin': 'Armiger Knight-Helverins',
    'knight-household-aspirants': 'Aspirants',
    'knight-household-seneschal': 'Seneschal',
    'knight-household-lord-scion': 'Lord Scion',
    'knight-household-preceptor': 'Preceptor',

    'solar-auxilia-command-detachment': 'Command Section',
    'solar-auxilia-lord-marshall': 'Lord Marshall',
    'solar-auxilia-tactical-command-section': 'Tactical Command Section',
    'solar-auxilia-veletaris-storm-section': 'Veletaris Storm Section',
    'solar-auxilia-veletaris-storm-cohort': 'Veletaris Storm Cohort',
    'solar-auxilia-infantry-tercio': 'Infantry Tercio',
    'solar-auxilia-infantry-section': 'Infantry Section',
    'solar-auxilia-strike-company': 'Strike Squadron',
    'solar-auxilia-close-support-squadron': 'Close Support Squadron',
    'solar-auxilia-leman-russ': 'Leman Russ',
    'solar-auxilia-leman-russ-executioner': 'Leman Russ Executioner',
    'solar-auxilia-leman-russ-incinerator': 'Leman Russ Incinerator',
    'solar-auxilia-leman-russ-exterminator': 'Leman Russ Exterminator',
    'solar-auxilia-leman-russ-demolisher': 'Leman Russ Demolisher',
    'solar-auxilia-leman-russ-vanquisher': 'Leman Russ Vanquisher',
    'solar-auxilia-stormlord': 'Stormlord',
    'solar-auxilia-close-support-section': 'Close Support Section',
    'solar-auxilia-veletaris-support-squad': 'Veletaris Support Squad',
    'solar-auxilia-ogryn-charonite-squad': 'Ogryn Charonite Squad',
    'solar-auxilia-malcador-infernus': 'Malcador Infernus',
    'solar-auxilia-infantry-support-tank-unit': 'Infantry Support Tank',
    'solar-auxilia-dracosan': 'Dracosan Armoured Transport',
    'solar-auxilia-stormlord-transport': 'Stormlord',
    'solar-auxilia-arvus-lighter': 'Arvus Lighter',
    'solar-auxilia-rapier': 'Rapier',
    'solar-auxilia-orbital-support': 'Orbital Support',
    'solar-auxilia-dauntless-class-light-cruiser': 'Dauntless Class Light Cruiser',
    'solar-auxilia-emperor-class-battleship': 'Emperor Class Battleship',
    'solar-auxilia-medusa': 'Medusa',
    'solar-auxilia-artillery-tank-battery': 'Artillery Tank Battery',
    'solar-auxilia-basilisk': 'Basilisk',
    'solar-auxilia-bombard': 'Bombard',
    'solar-auxilia-malcador-squadron': 'Malcador Squadron',
    'solar-auxilia-malcador': 'Malcador',
    'solar-auxilia-super-heavy-tank': 'Super Heavy Tank',
    'solar-auxilia-super-heavy-tank-squadron': 'Super Heavy Tank Squadron',
    'solar-auxilia-baneblade': 'Baneblade',
    'solar-auxilia-shadowsword': 'Shadowsword',
    'solar-auxilia-stormblade': 'Stormblade',
    'solar-auxilia-stormhammer': 'Stormhammer',
    'solar-auxilia-stormsword': 'Stormsword',
    'solar-auxilia-baneblade-squadron-unit': 'Baneblade',
    'solar-auxilia-shadowsword-squadron-unit': 'Shadowsword',
    'solar-auxilia-stormblade-squadron-unit': 'Stormblade',
    'solar-auxilia-stormlord-squadron-unit': 'Stormlord',
    'solar-auxilia-stormhammer-squadron-unit': 'Stormhammer',
    'solar-auxilia-stormsword-squadron-unit': 'Stormsword',
    'solar-auxilia-avenger-wing': 'Avenger Strike Fighter Wing',
    'solar-auxilia-avenger-strike-fighter': 'Avenger Strike Fighter',
    'solar-auxilia-primaris-wing': 'Primaris-Lighting Wing',
    'solar-auxilia-primaris-strike-fighter': 'Primaris-Lighting Fighter',
    'solar-auxilia-tank-hunter-squadron': 'Tank Hunter Squadron',
    'solar-auxilia-valdor': 'Valdor Tank Hunter',
    'solar-auxilia-tarantula-battery': 'Tarantula Battery',
    'solar-auxilia-tarantula': 'Tarantula',
    'solar-auxilia-tarantula-hyperios': 'Tarantula Hyperios',

    'daemonic-hordes-warp-rift-detachment': 'Warp Rift',
    'daemonic-hordes-warp-rift': 'Warp Rift',
    'daemonic-hordes-khorne-horde': 'Khorne Horde',
    'daemonic-hordes-herald-of-khorne': 'Herald of Khorne',
    'daemonic-hordes-daemon-prince-of-khorne': 'Daemon Prince of Khorne',
    'daemonic-hordes-bloodletters': 'Bloodletters',
    'daemonic-hordes-nurgle-horde': 'Nurgle Horde',
    'daemonic-hordes-herald-of-nurgle': 'Herald of Nurgle',
    'daemonic-hordes-daemon-prince-of-nurgle': 'Daemon Prince of Nurgle',
    'daemonic-hordes-plague-bearers': 'Plague Bearers',
    'daemonic-hordes-slaanesh-horde': 'Slaanesh Horde',
    'daemonic-hordes-herald-of-slaanesh': 'Herald of Slaanesh',
    'daemonic-hordes-daemon-prince-of-slaanesh': 'Daemon Prince of Slaanesh',
    'daemonic-hordes-daemonettes': 'Daemonettes',
    'daemonic-hordes-tzeench-horde': 'Tzeench Horde',
    'daemonic-hordes-herald-of-tzeench': 'Herald of Tzeench',
    'daemonic-hordes-daemon-prince-of-tzeench': 'Daemon Prince of Tzeench',
    'daemonic-hordes-horrors-of-tzeench': 'Horrors',
    'daemonic-hordes-khorne-followers': 'Followers of Khorne',
    'daemonic-hordes-bloodcrushers': 'Blood Crushers',
    'daemonic-hordes-bloodcrushers-follower-unit': 'Blood Crushers',
    'daemonic-hordes-flesh-hounds': 'Flesh Hounds',
    'daemonic-hordes-flesh-hounds-follower-unit': 'Flesh Hounds',
    'daemonic-hordes-skull-cannon-of-khorne': 'Skull Cannon of Khorne',
    'daemonic-hordes-skull-cannon-of-khorne-follower-unit': 'Skull Cannon of Khorne',
    'daemonic-hordes-nurgle-followers': 'Followers of Nurgle',
    'daemonic-hordes-beasts-of-nurgle': 'Beasts of Nurgle',
    'daemonic-hordes-beasts-of-nurgle-follower-unit': 'Beasts of Nurgle',
    'daemonic-hordes-nurglings': 'Nurglings',
    'daemonic-hordes-nurglings-follower-unit': 'Nurglings',
    'daemonic-hordes-plague-drones': 'Plague Drones',
    'daemonic-hordes-plague-drones-follower-unit': 'Plague Drones',
    'daemonic-hordes-slaanesh-followers': 'Followers of Slaanesh',
    'daemonic-hordes-seekers-of-slaanesh': 'Seekers of Slaanesh',
    'daemonic-hordes-seekers-of-slaanesh-follower-unit': 'Seekers of Slaanesh',
    'daemonic-hordes-seeker-chariot': 'Seeker Chariot',
    'daemonic-hordes-seeker-chariot-follower-unit': 'Seeker Chariot',
    'daemonic-hordes-fiends-of-slaanesh': 'Fiends of Slaanesh',
    'daemonic-hordes-fiends-of-slaanesh-follower-unit': 'Fiends of Slaanesh',
    'daemonic-hordes-tzeench-followers': 'Followers of Tzeench',
    'daemonic-hordes-flamers-of-tzeench': 'Flamers of Tzeench',
    'daemonic-hordes-flamers-of-tzeench-follower-unit': 'Flamers of Tzeench',
    'daemonic-hordes-screamers-of-tzeench': 'Screamers of Tzeench',
    'daemonic-hordes-screamers-of-tzeench-follower-unit': 'Screamers of Tzeench',
    'daemonic-hordes-burning-chariot-of-tzeench': 'Burning Chariot of Tzeench',
    'daemonic-hordes-burning-chariot-of-tzeench-follower-unit': 'Burning Chariot of Tzeench',
    'daemonic-hordes-chaos-undivided-fury-flight': 'Fury Flight',
    'daemonic-hordes-chaos-undivided-fury-flight-unit': 'Chaos Furies',
    'daemonic-hordes-chaos-undivided-spawn-pack': 'Chaos Spawn Pack',
    'daemonic-hordes-chaos-fury': 'Chaos Furies',
    'daemonic-hordes-chaos-spawn': 'Chaos Spawn',
    'daemonic-hordes-chaos-undivided-spawn-pack-unit': 'Chaos Spawn',
    'daemonic-hordes-chaos-undivided-soul-grinder-maniple': 'SoulGrinder Maniple',
    'daemonic-hordes-soul-grinder': 'Soul Grinder',
    'daemonic-hordes-chaos-undivided-soul-grinder-maniple-unit': 'Soul Grinder',
    'daemonic-hordes-khorne-greater-daemon-horde': 'Khorne Greater Daemon Horde',
    'daemonic-hordes-nurgle-greater-daemon-horde': 'Nurgle Greater Daemon Horde',
    'daemonic-hordes-slaanesh-greater-daemon-horde': 'Slaanesh Greater Daemon Horde',
    'daemonic-hordes-tzeench-greater-daemon-horde': 'Tzeench Greater Daemon Horde',
    'daemonic-hordes-blood-thirster-horde-unit': 'Bloodthirster',
    'daemonic-hordes-great-unclean-one-horde-unit': 'Great Unclean One',
    'daemonic-hordes-keeper-of-secrets-horde-unit': 'Keeper of Secrets',
    'daemonic-hordes-lord-of-change-horde-unit': 'Lord of Change',
    'daemonic-hordes-blood-thirster': 'Bloodthirster',
    'daemonic-hordes-great-unclean-one': 'Great Unclean One',
    'daemonic-hordes-keeper-of-secrets': 'Keeper of Secrets',
    'daemonic-hordes-lord-of-change': 'Lord of Change',
    'daemonic-hordes-chaos-altar': 'Chaos Altar',

    'daemon-blade': 'Daemon Blade',
    'daemon-weapon': 'Daemon Weapon',
    hellblades: 'Hellblades',
    wings: 'Wings',
    'sorcerous-blast': 'Sorcerous Blast',
    'warp-blast': 'Warp Blast',
    'plague-swords': 'Plague Swords',
    talons: 'Talons',
    'crushing-claws': 'Crushing Claws',
    'sopoforic-musk': 'Sopoforic Musk',
    'warp-tempest': 'Warp Tempest',
    'daemonic-fire': 'Daemonic Fire',
    'juggernaut-charge': 'Juggernaut Charge',
    'fangs-and-claws': 'Fangs & Claws',
    'skull-cannon': 'Skull Cannon',
    'acidic-slime': 'Acidic Slime',
    'cloud-of-flies': 'Cloud of Flies',
    'tiny-sharp-teeth': 'Tiny Sharp Teeth',
    'daemonic-talons': 'Daemonic Talons',
    fleshshredder: 'Fleshshredder',
    'rending-claws': 'Rending Claws',
    flames: 'Flames',
    'exalted-flamer': 'Exalted Flamer',
    'aerial-assault': 'Aerial Assault',
    'harvester-claws': 'Harvester Claws',
    phlegm: 'Phlegm',
    vomit: 'Vomit',
    'axe-of-khorne': 'Axe of Khorne',
    'lash-of-khorne': 'Lash of Khorne',
    'nurgling-swarm': 'Nurgling Swarm',
    'stream-of-corruption': 'Stream of Corruption',
    'lash-of-torment': 'Lash of Torment',
    'gaze-of-slaanesh': 'Gaze of Slaanesh',
    'bedlam-staff': 'Bedlam Staff',
    'withering-gaze': 'Withering Gaze',
    'arcane-tech': 'Arcane Tech',

    chainswords: 'Chainswords',
    'heavy-bolter': 'Heavy Bolter',
    'earthshaker-cannon': 'Earthshaker Cannon',
    'orbital-bombardment': 'Orbital Bombardment',
    'melta-cutters': 'Melta-cutters',
    'firefury-missile': 'Firefury Missile',
    'magna-melta': 'Magna Melta',
    'neutron-laser-battery': 'Neutron Laser Battery',
    'sponson-lascannons': '2x Lascannons',
    'sponson-twin-linked-lascannons': '2x Twin Linked Lascannons',
    'sponson-heavy-flamers': '2x Heavy Flamers',
    'sponson-heavy-bolters': '2x Heavy Bolters',
    'sponson-flamestorm-cannons': '2x Flamestorm Cannons',
    'charnabal-sabre': 'Charnabal Sabre',
    'crozius-arcanum': 'Crozius Arcanum',
    'close-combat-weapon': 'Close Combat Weapon',
    'close-combat-weapons': 'Close Combat Weapons',
    'twin-linked-heavy-bolters': 'Twin Linked Heavy Bolters',
    'plasma-cannon': 'Plasma Cannon',
    'heavy-conversion-beamer': 'Heavy Conversion Beamer',
    'kheres-assault-cannon': 'Kheres Assault Cannon',
    'twin-linked-autocannon': 'Twin Linked Autocannon',
    '2-twin-linked-autocannon': '2x Twin Linked Autocannon',
    'multi-melta': 'Multi-Melta',
    'twin-linked-lascannon': 'Twin Linked Lascannon',
    'havoc-launcher': 'Havoc Launcher',
    'combi-bolter': 'Combi-bolter',
    'chain-fists': 'Chain-fists',
    'aiolos-missile-launcher': 'Aiolos Missile Launcher',
    'helitical-targeting-array': 'Helitical Targeting Array',
    'anvilus-autocannon-battery': 'Anvilus Autocannon Battery',
    'hellfire-plasma-cannon': 'Hellfire Plasma Cannon',
    'assault-launcher': 'Assault Launcher',
    '2x-assault-launcher': '2x Assault Launchers',
    'twin-linked-missile-launcher': 'Twin Linked Missile Launcher',
    'power-fist': 'Power Fist',
    'power-fists': 'Power Fists',
    'deathstorm-bombardment': 'Deathstorm Bombardment',
    'twin-linked-volcano-cannon': 'Twin Linked Volcano Cannon',
    'sponson-quad-lascannons': '2x Quad Lascannon',
    'sponson-quad-heavy-bolters': '2x Quad Heavy Bolters',
    'sponson-reaper-autocannons': '2x Reaper Autocannon Battery',
    'fellblade-cannon': 'Fellblade Cannon',
    'demolisher-cannon': 'Demolisher Cannon',
    'twin-linked-avenger-bolt-cannon': 'Twin Linked Avenger Bolt Cannon',
    'hellstrike-missile-pod': 'Hellstrike Missile Pod',
    'volkite-carronade': 'Volkite Carronade',
    'heavy-weapons': 'Heavy Weapons',
    'flak-missiles': 'Flak Missiles',
    'twin-linked-cyclone-missile-launcher': 'Twin Linked Cyclone Missile Launcher',
    'storm-launcher': 'Storm Launcher',
    'melta-ram': 'Melta Ram',
    'heat-blast': 'Heat Blast',
    'achilles-quad-mortar': 'Achilles Quad Mortar',
    'quad-mortar': 'Quad Mortar',
    'sponson-twin-linked-multi-meltas': '2x Twin Linked Multi-Meltas',
    'sponson-twin-linked-volkite-culverins': '2x Twin Linked Volkite Culverins',
    'siege-claw': 'Siege Claw',
    'siege-drill': 'Siege Drill',
    'cyclonic-melta-lance': 'Cyclonic Melta Lance',
    'storm-cannon': 'Storm Cannon',
    'grav-flux-bombard': 'Grav Flux Bombard',
    'force-weapon': 'Force Weapon',
    smite: 'Smite',
    'paragon-blade': 'Paragon Blade',
    'medusa-siege-cannon': 'Medusa Siege Cannon',
    'siege-melta-array': 'Siege Melta Array',
    'skyreaper-battery': 'Skyreaper Battery',
    'plasma-destroyer': 'Plasma Destroyer',
    'magma-melta': 'Magma Melta',
    'predator-cannon': 'Predator Cannon',
    'flamestorm-cannon': 'Flamestorm Cannon',
    'laser-destroyer': 'Laser Destroyer',
    'quad-heavy-bolters': 'Quad Heavy Bolters',
    'graviton-cannon': 'Graviton Cannon',
    'sniper-rifles': 'Sniper Rifles',
    'omega-plasma-array': 'Omega Plasma Array',
    'neutron-beam': 'Neutron Beam',
    'accelerator-cannon': 'Accelerator Cannon',
    '3-heavy-bolters': '3x Heavy Bolters',
    '2-twin-linked-heavy-bolters': '2x Twin Linked Heavy Bolters',
    '3-twin-linked-heavy-bolters': '3x Twin Linked Heavy Bolters',
    '2-twin-linked-lascannons': '2x Twin Linked Lascannons',
    '4-twin-linked-lascannons': '4x Twin Linked Lascannons',
    'vengeance-launcher': 'Vengeance Launcher',
    'dreadstrike-missile-array': 'Dreadstrike Missile Array',
    bolters: 'Bolters',
    'special-weapons': 'Special Weapons',
    'reaper-autocannon': 'Reaper Autocannon',
    '2-reaper-autocannon': '2x Reaper Autocannon',
    'thunderhawk-cannon': 'Thunderhawk Cannon',
    'thunderhawk-laser-destroyer': 'Thunderhawk Laser Destroyer',
    'dreadhammer-siege-cannon': 'Dreadhammer Siege Cannon',
    'laser-destroyer-array': 'Laser Destroyer Array',
    'hyperios-launcher': 'Hyperios Launcher',
    'scorpius-multi-launcher': 'Scorpius Multi Launcher',
    'vengeance-castellan-missiles': 'Vengeance & Castellan Missiles',
    'rotary-missile-launcher': 'Rotary Missile Launcher',
    'power-axes': 'Power Axes',
    'world-breaker': 'World Breaker',
    'warmasters-talon': 'Warmaster\'s Talon',
    'signum-bolt-pistols': 'Signum Bolt Pistols',
    'mercy-and-forgiveness': 'Mercy and Forgiveness',
    'the-widowmakers': 'The Widowmakers',
    'power-weapons': 'Power Weapons',
    'power-weapon': 'Power Weapon',
    chainglaives: 'Chainglaives',
    'bolt-pistols': 'Bolt Pistols',
    'gorefather-and-gorechild': 'Gorefather & Gorechild',
    'the-spite-furnace': 'The Spite Furnace',
    'cadere-weapons': 'Cadere Weapons',
    'blade-of-the-laer': 'Blade of the Laer',
    firebrand: 'Firebrand',
    'dueling-weapons': 'Dueling Weapons',
    'the-cacophony': 'The Cacophony',
    'phoenix-power-spear': 'Phoenix Power Spear',
    'the-logos': 'The Logos',
    'wrist-cannon': 'Wrist Cannon',
    'havoc-autocannons': 'Havoc Autocannons',
    'cyclone-missile-launcher': 'Cyclone Missile Launcher',
    'plasma-blastgun': 'Plasma Blastgun',
    'volcano-cannon': 'Volcano Cannon',
    'assault-grenade-launchers': 'Assault Grenade Launchers',
    'power-scythes': 'Power Scythes',
    'chem-flamers': 'Chem-flamers',
    silence: 'Silence',
    lantern: 'Lantern',
    illuminarum: 'Illuminarum',
    'volkite-pistol': 'Volkite Pistol',
    'burning-lore': 'Burning Lore',
    'daemonic-blade': 'Daemonic Blade',
    'axe-rakes': 'Axe-rakes',
    'inferno-pistols': 'Inferno Pistols',
    'tainted-power-claw': 'Tainted Power Claw',
    'warpfire-plasma-cannon': 'Warpfire Plasma Cannon',
    'volkite-chargers': 'Volkite Chargers',
    'pale-spear': 'Pale Spear',
    'master-crafted-boltguns': 'Master Crafted Boltguns',
    'force-weapons': 'Force Weapons',
    'force-blast': 'Force Blast',
    'force-blades': 'Force Blades',
    'blade-of-ahn-nunurta': 'The Blade of Ahn Nunurta',
    'psyfire-serpenta': 'Psyfire Serpenta',
    'graviton-weapons': 'Graviton Weapons',
    forgebreaker: 'Forgebreaker',
    'the-medusan-carapace': 'The Medusan Carapace',
    'lightning-claws': 'Lighting Claws',
    'combi-weapons': 'Combi-Weapons',
    'combi-weapon': 'Combi-Weapon',
    'eclipse-missiles': 'Eclipse Missiles',
    'power-whip': 'Power Whip',
    'modified-archeotech-pistols': 'Modified Archeotech Pistols',
    'thunder-hammer-and-shield': 'Thunder Hammer and Shield',
    'combi-bolters': 'Combi-Bolters',
    'flame-projector': 'Flamer Projector',
    dawnbringer: 'Dawnbringer',
    'the-furnaces-heart': 'The Furnaces Heart',
    'legatine-axes': 'Legatine Axes',
    'plasma-pistols': 'Plasma Pistols',
    'hand-of-domninon': 'Hand of Dominion',
    arbitrator: 'Arbitrator',
    '2-hyperios-battery': '2x Hyperios Battery',
    'turbolaser-destructor': 'Turbolaser Destructor',
    'storms-teeth': 'Storm\'s Teeth',
    'the-voice-of-terra': 'The Voice of Terra',
    'the-spear-of-telesto': 'The Spear of Telesto',
    'archaeotech-pistol': 'Archaeotech Pistol',
    'the-lion-sword': 'The Lion Sword',
    'master-crafted-volkite-pistol': 'Master Crafted Volkite Pistol',
    'vengeance-lance': 'Vengeance Lance',
    'sabre-of-unity': 'Sabre of Unity',
    'bolt-pistol': 'Bolt Pistol',
    'frost-weapons': 'Frost Weapons',
    mjalnar: 'Mjalnar',
    'wolves-of-fenrir': 'Wolves of Fenrir',
    'vulcan-mega-bolter': 'Vulcan Mega Bolter',
    'inferno-gun': 'Inferno Gun',
    'apocalypse-missile-launcher': 'Apocalypse Missile Launcher',
    'carapace-landing-pad': 'Carapace Landing Pad',
    'corvus-assault-pod': 'Corvus Assault Pod',
    'gatling-blaster': 'Gatling Blaster',
    'laser-blaster': 'Laser Blaster',
    'laser-burner': 'Laser Burner',
    'melta-cannon': 'Melta Cannon',
    'quake-cannon': 'Quake Cannon',
    'plasma-destructor': 'Plasma Destructor',
    'support-missile-vortex': 'Vortex Missile',
    'support-missile-warp': 'Warp Missile',
    'support-missile-deathstrike': 'Deathstrike Missile',
    'support-missile-barrage': 'Barrage Missile',
    'multi-lasers': 'Multi-Lasers',
    'multi-laser': 'Multi-Laser',
    'plasma-annihilator': 'Plasma Annihilator',
    'hellstorm-cannon': 'Hellstorm Cannon',
    'defence-laser': 'Defence Laser',
    '4-battle-cannons': '4x Battle Cannons',
    'leg-bastions': 'Leg Bastions',
    '8-support-missiles': '8x Support Missiles',
    'vengeance-cannon': 'Vengeance Cannon',
    '4-hydra-autocannon': '4x Hydra Autocannon',
    'fire-control-centre': 'Fire Control Centre',
    'head-gun': 'Head Gun',
    'tertiary-armament': 'Tertiary Armament',
    'power-lance': 'Power Lance',
    'iliastus-bolt-cannon': 'Iliastus Bolt Cannon',
    'sentinel-warblade': 'Sentinel Warblade',
    'guardian-spear': 'Guardian Spear',
    'lastrum-storm-bolter': 'Lastrum Storm Bolter',
    'solerite-power-gauntlet': 'Solerite Power Gauntlet',
    'iliastus-accelerator-cannon': 'Iliastus Accelerator Cannon',
    'arachnus-blaze-cannon': 'Arachnus Blaze Cannon',
    'apollonian-spear': 'Apollonian Spear',
    'achilles-dreadspear': 'Achilles Dreadspear',
    'galatus-warblade': 'Galatus Warblade',
    '2-telemon-caestus': '2x Telemon Caestus',
    'arachnus-storm-cannon': 'Arachnus Storm Cannon',
    'lastrum-bolt-cannon': 'Lastrum Bolt Cannon',
    '2-lastrum-bolt-cannon': '2x Lastrum Bolt Cannon',
    '2-spiculus-heavy-bolt-launchers': '2x Spiculus Heavy Bolt Launchers',
    '2-arachnus-heavy-blaze-cannon': '2x Arachnus Heavy Blaze Cannon',
    'arachnus-magna-blaze-cannon': 'Arachnus Magna-Blaze Cannon',
    'plasma-guns': 'Plasma Guns',
    'auxilia-rifles': 'Auxilia Rifles',
    'auxilia-pistols-and-combat-weapons': 'Auxilia Pistols and Combat Weapons',
    'heavy-stubbers': 'Heavy Stubbers',
    'auxilia-pistol-and-combat-weapon': 'Auxilia Pistol and Combat Weapon',
    lascarbines: 'Lascarbines',
    'auxilia-weapons': 'Auxilia Weapons',
    'gorgon-mortars': 'Gorgon Mortars',
    'ripper-guns': 'Ripper Guns',
    'chaos-spawn-mutations': 'Chaos Spawn Mutations',
    'pin-point-attack': 'Pin-Point Attack',
    'power-lances': 'Power Lances',
    'crew-lascarbines': 'Crew Lascarbines',
    'medusa-siege-gun': 'Medusa Siege Gun',
    'battle-cannon': 'Battle Cannon',
    'stormhammer-cannon': 'Stormhammer Cannon',
    lascannon: 'Lascannon',
    '6-heavy-bolters': '6x Heavy Bolters',
    '2-battlecannon': 'Twin Linked Battlecannon',
    'baneblade-cannon': 'Baneblade Cannon',
    autocannon: 'Autocannon',
    'vanquisher-cannon': 'Vanquisher Cannon',
    'avenger-cannon': 'Avenger Cannon',
    'heavy-stubber': 'Heavy Stubber',
    'kraken-heavy-missiles': 'Kraken Heavy Missiles',
    'sponson-autocannons': '2x Autocannons',
    'psychic-bolt': 'Psychic Bolt',
    'las-locks': 'Las-Locks',
    '2-graviton-guns': '2x Graviton Guns',
    'pulsar-fusil': 'Pulsar Fusil',
    'mechadendrite-array': 'Mechadendrite Array',
    'twin-linked-mauler-bolt-cannons': 'Twin Maulter Bolt Cannons',
    'mauler-bolt-cannon': 'Maulter Bolt Cannon',
    'shock-ram': 'Shock Ram',
    'rotor-cannons': 'Rotor Cannons',
    'power-blade': 'Power Blade',
    'hellex-plasma-mortar': 'Hellex Plasma Mortar',
    'sollex-heavy-lascannon': 'Sollex Heavy Lascannon',
    'volkite-culverins': 'Volkite Culverins',
    'minotaur-earthshaker-cannons': 'Minotaur Earthshaker Cannons',
    'bellacosa-volcano-cannon': 'Bellacosa Volcano Cannon',
    'ulator-sonic-destroyer': 'Ulator Sonic Destroyer',
    '3-volkite-culverins': '3x Volkite Culverins',
    'karacnos-mortar-battery': 'Karacnos Mortar Battery',
    'lighting-blaster-sentinels': 'Lighting Blaster Sentinels',
    'sonic-disruptor': 'Sonic Disruptor',
    '6-golgothan-missiles': '6x Golgothan Missiles',
    'nova-cannon': 'Nova Cannon',
    'questoris-battle-cannon': 'Questoris Battle Cannon',
    'thermal-cannon': 'Thermal Cannon',
    'reaper-chainsword': 'Reaper Chainsword',
    'master-knight-commander': 'Master Knight Commander',
    'rapid-fire-battle-cannon': 'Rapid Fire Battle Cannon',
    'avenger-gatling-cannon': 'Avenger Gatling Cannon',
    'twin-linked-icarus-autocannon': 'Twin Linked Icarus Autocannon',
    'thunderstrike-gauntlet': 'Thunderstrike Gauntlet',
    'stormspear-rocket-pod': 'Stormspear Rocket Pod',
    'lightning-cannon': 'Lightning Cannon',
    'twin-linked-rad-cleanser': 'Twin Linked Rad Cleanser',
    'hekaton-siege-claw': 'Hekaton Siege Claw',
    'volkite-chieorovile': 'Volkite Chieorovile',
    'ironstorm-missile-pod': 'Ironstorm Missile Pod',
    'cerastus-shock-lance': 'Cerastus Shock Lance',
    'shock-blast': 'Shock Blast',
    'castigator-pattern-bolt-cannon': 'Castigator Pattern Bolt Cannon',
    'tempest-warblade': 'Tempest Warblade',
    'atropos-lascutter': 'Atropos Lascutter',
    'graviton-singularity-cannon': 'Graviton Singularity Cannon',
    'flame-cannon': 'Flame Cannon',
    'destroyer-chainfist': 'Destroyer Chainfist',
    '2-twin-linked-magna-lascannon': '2x Twin Linked Magna Lascannon',
    'las-rifles': 'Las-Rifles',
    'infernus-cannon': 'Infernus Cannon',
    'lance-battery': 'Lance Battery',
    'siege-mortar': 'Siege Mortar',
    '2-heavy-bolters': '2x Heavy Bolters',
    'stormsword-siege-cannon': 'Stormsword Siege Cannon',
    'twin-linked-volkite-demi-culverin': 'Twin-Linked Volkite Demi-Culverin',
    'neutron-beam-laser': 'Neutron Beam Laser',
    'daemonic-overlord': 'Daemonic Overlord',
    flamers: 'Flamers',
    'support-weapons': 'Support Weapons',
    'melta-bombs': 'Melta Bombs',
    '2-armiger-autocannon': '2x Armiger Autocannon',
    'reaper-chain-cleaver': 'Reaper Chain Cleaver',
    'thermal-spear': 'Thermal Spear',
    'charonite-claws': 'Charonite Claws',
    '2-heavy-flamers': '2x Heavy Flamers',
    'kinetic-destroyer': 'Kinetic Destroyer',
    'power-glaive': 'Power Glaive',
    'scatterbolt-launcher': 'Scatterbolt Launcher',
    'kontos-power-lance': 'Kontos Power Lance',
    'olympia-bolt-cannon': 'Olympia Bolt Cannon',
    'graviton-maul': 'Graviton Maul',
    'chainswords-and-bolt-pistols': 'Chainswords & Bolt Pistols',
    'plasma-weapons': 'Plasma Weapons',
    'plasma-burners': 'Plasma Burners',
    'iliastus-assault-cannon': 'Iliastus Assault Cannon',
    'falling-star-pattern-power-spear': 'Falling Star Pattern Power Spear',
    '2x-setheno-havoc-launcher': '2x Setheno Havoc Launcher',
    'vultarax-arc-blaster': 'Vultarax Arc Blaster',
    'adrastus-bolt-caliver': 'Adrastus Bolt Caliver',

    none: 'None',
    sniper: 'Sniper',
    'reinforced-armour': 'Reinforced Armour',
    'thick-rear-armour': 'Thick Rear Armour',
    'jump-packs': 'Jump Packs',
    scout: 'Scout',
    skimmer: 'Skimmer',
    'exploratory-augury-web': 'Exploratory Augury Web',
    mounted: 'Mounted',
    infiltrator: 'Infiltrator',
    'demi-god': 'Demi God',
    fearless: 'Fearless',
    'supreme-commander': 'Supreme Commander',
    inspiring: 'Inspiring',
    'invulnerable-save': 'Invulnerable Save',
    'invulnerable-save-cc-only': 'Invulnerable Save against CC generated hits',
    walker: 'Walker',
    planetfall: 'Planetfall',
    commander: 'Commander',
    leader: 'Leader',
    'damage-capacity': 'Damage Capacity',
    'critical-hit': 'Critical hit',
    'slow-and-steady': 'Slow and Steady',
    'macro-weapon': 'Macro Weapon',
    'titan-killer': 'Titan Killer',
    'extra-attacks': 'Extra Attacks',
    'anti-personnel': 'Anti Personnel',
    'anti-tank': 'Anti Tank',
    'anti-aircraft': 'Anti Aircraft',
    disrupt: 'Disrupt',
    'ignore-cover': 'Ignore Cover',
    lance: 'Lance',
    'indirect-fire': 'Indirect Fire',
    'fixed-forward-fire-arc': 'Fixed Forward Fire Arc',
    'single-shot': 'Single Shot',
    feedback: 'Feedback',
    'forward-fire-arc': 'Forward Fire Arc',
    'left-fire-arc': 'Left Fire Arc',
    'right-fire-arc': 'Right Fire Arc',
    'rear-fire-arc': 'Rear Fire Arc',
    'first-strike': 'First Strike',
    'cortex-controller': 'Cortex Controller',
    'slow-firing': 'Slow Firing',
    ferocity: 'Ferocity',
    'secondary-targeting-protocols': 'Secondary Targeting Protocols',
    titanic: 'May step over impassable terrain or dangerous terrain that is lower than the titan\'s knees and no greater than 2cm wide.',
    'titanic-effect': 'Titan: {{titanicEffect}}',
    'left-carapace': 'Left Carapace Weapon -',
    'right-carapace': 'Right Carapace Weapon -',
    carapace: 'Carapace Weapon -',
    'left-arm': 'Left Arm Weapon -',
    'right-arm': 'Right Arm Weapon -',
    berserk: 'Berserk',
    automaton: 'Automaton',
    'cybernetica-cortex': 'Cybernetica Cortex',
    singularity: 'Singularity',
    'implacable-advance': 'Implacable Advance',
    'ion-shield': 'Ion Shield',
    'ion-gauntlet': 'Ion Gauntlet',
    teleport: 'Teleport',
    'chaos-gate': 'Chaos Gate',
    veteran: 'Veteran',
    fighter: 'Fighter',
    bomber: 'Bomber',
    'fighter-bomber': 'Fighter-Bomber',
    fleshbane: 'Fleshbane',
    armourbane: 'Armourbane',
    siege: 'Siege',
    'a-talent-for-murder': 'A Talent for Murder',

    'imperial-militia-warrior-elite-provenance': 'Warrior Elite Provenance',
    'imperial-militia-survivors-of-the-dark-age-provenance': 'Survivors of the Dark Age Provenance',
    'imperial-militia-feral-warriors-provenance': 'Feral Warriors Provenance',
    'imperial-militia-traitors-provenance': 'Traitors Provenance',

    'critical-hit-effect': 'Critical Hit: {{criticalHitEffect}}',
    'legion-spartan-critical-hit': 'Unit is destroyed and all units within 5cm suffer a standard hit on a 6+',
    'legio-titanicus-warhound-critical-hit': 'The Warhound staggers D6cm in a random direction taking an additional DC, any units that come into base contact with the Warhound as it staggers take a standard hit on a 6+',
    'legio-titanicus-reaver-critical-hit': 'Roll a D6 in the end phase for the remainder of the game, on a 1, the Titan\'s reactor explodes, destroying it completely and hitting all units within 5cm on a standard 6+ to hit, on a 2-3, the Titan loses a point of DC, 4-6 the reactor is repaired.',
    'legio-titanicus-warlord-critical-hit': 'Roll a D6 in the end phase for the remainder of the game, on a 1, the Titan\'s reactor explodes, destroying it completely and hitting all units within 5cm on a standard 4+ to hit, on a 2-3, the Titan loses a point of DC, 4-6 the reactor is repaired.',
    'legio-titanicus-imperator-critical-hit': 'Roll a D6 in the end phase for the remainder of the game, on a 1, the Titan\'s reactor explodes, destroying it completely and hitting all units within 5cm on a standard 4+ to hit, on a 2-3, the Titan loses a point of DC, 4-6 the reactor is repaired.',
    'legio-titanicus-warmonger-critical-hit': 'Roll a D6 in the end phase for the remainder of the game, on a 1, the Titan\'s reactor explodes, destroying it completely and hitting all units within 5cm on a standard 4+ to hit, on a 2-3, the Titan loses a point of DC, 4-6 the reactor is repaired.',
    'imperial-militia-gorgon-critical-hit': ' The Gorgon is Immoblised and D6 infantry units inside the Gorgon take a hit. Further Critical Hits destroy the Gorgon.',
    'imperial-militia-baneblade-critical-hit': 'Destroyed. any units within 5cm of the model suffer a hit on a roll of 6+',
    'imperial-militia-stormhammer-critical-hit': 'Destroyed. any units within 5cm of the model suffer a hit on a roll of 6+',
    'mechanicum-taghmata-falchion-critical-hit': 'Unit is destroyed and all units within 5cm suffer a standard hit on a 6+',
    'mechanicum-taghmata-ordinatus-minoris-critical-hit': 'The Ordinatus is destroyed. All units within 15cm suffer a hit on a 5+.',
    'mechanicum-taghmata-ordinatus-majoris-critical-hit': 'The Ordinatus is destroyed. All units within 15cm suffer a hit on a 5+. All friendly formations within line of sight take a Blast Marker.',
    'knight-critical-hit': 'Destroyed',
    'solar-auxilia-dracosan-critical-hit': 'The unit is destroyed. All units within 5cm take a standard hit on a 6+.',
    'solar-auxilia-baneblade-critical-hit': 'The unit is destroyed. any units within 5cm of the model suffer a hit on a roll of 6+',
    'solar-auxilia-shadowsword-critical-hit': 'The unit is destroyed. any units within 5cm of the model suffer a hit on a roll of 6+',
    'solar-auxilia-stormblade-critical-hit': 'The unit is destroyed. any units within 5cm of the model suffer a hit on a roll of 6+',
    'solar-auxilia-stormhammer-critical-hit': 'The unit is destroyed. any units within 5cm of the model suffer a hit on a roll of 6+',
    'solar-auxilia-stormlord-critical-hit': 'The unit is destroyed. any units within 5cm of the model suffer a hit on a roll of 6+',
    'solar-auxilia-stormsword-critical-hit': 'The unit is destroyed. any units within 5cm of the model suffer a hit on a roll of 6+',
    'daemonic-hordes-greater-daemon-critical-hit': 'Hurled back into the warp. Drags any units within 5cm back with it on a roll of 6+.',
    'daemonic-hords-chaos-altar-critical-hit': 'Destroyed. Units within 5cm suffer MW6+ attack',

    'carapace-landing-pad-notes': 'Grants BP weapons the Indirect Fire special rule',
    'corvus-assault-pod-notes': 'Transport. May carry 10 infantry units. Terminators, Breachers, Thallax, Ursarax, Myrmidon take up 2 spaces each.',
    'fire-control-notes': 'Re-roll one failed to hit roll from one weapons system per turn',
    'hekaton-siege-claw-notes': 'EA(+2), MW versus immobile targets',
    'knight-household-aspirants-notes': 'Formation suffers -1 to any Advance, Overwatch, March, Marshall and Sustained Fire action rolls',
    'knight-household-scions-of-uhlan-notes': 'Formation loses "Reinforced Armour" and gains "Scout"',
    'daemonic-hordes-warp-rift-notes-replaces': 'Replace one objective on the Daemon World side of the table with a Warp Rift.',
    'daemonic-hordes-warp-rift-notes-effect': 'The Warp Rift functions both as a Chaos Gate and as an objective for rules purposes. It may not be attacked or destroyed.'
  }
}
